import { useState } from "react"
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { FaMinus, FaPlus } from "react-icons/fa";
import { SwipeableDrawer } from "@material-ui/core";
import { HiOutlineMinus } from "react-icons/hi";
import { RiCloseCircleLine } from "react-icons/ri";
import { addCustomizationCart, clearCart, addToCart } from '../../Redux/Store/cartReducer';

const Customization = ({ product, storeList, cartPage, cardView, shop }) => {
    const dispatch = useDispatch();
    const [customizeOpen, setCustomizeOpen] = useState(false);
    const cartProducts = useSelector((state) => state.cart);
    const [quantity, setQuantity] = useState(1);
    const [otherStore, setOtherStore] = useState(false);
    const [stateItem, setStateItem] = useState(null);

    const addonoffer = (price) => {
        // console.log(price, 'price')
        if (product?.shop?.is_offer && parseFloat(product?.shop.offer_percentage) > 0) {
            return parseFloat(parseFloat(price) - parseFloat(price) * parseFloat(product?.shop?.offer_percentage) / 100).toFixed(1)
        } else {
            return parseFloat(price)
        }
    }

    const _processAddons = (item) => {
        let addons = [];
        addons["addonProducts"] = [];
        const index = cartProducts.items.findIndex((p) => p.id === item.id);
        // let radio = document.querySelectorAll("input[type=radio]:checked");
        let checkboxes = document.querySelectorAll("input[type=checkbox]:checked");

        if (checkboxes.length == 0) {
            let newData = Object.assign({}, item, {
                price: item['prices']['orignal_price'],
                selling_price: item['prices']['price'],
                shop: shop,
                quantity: 1,
                image: item?.images[0]?.url
            });
            if (cartProducts.items.length == 0 || newData?.shop_id === cartProducts?.shop_id) {
                dispatch(addToCart(newData));
            } else {
                setOtherStore(true);
                setStateItem(newData)
            }

            return;
        }

        if (index > -1) {
            cartProducts.items.forEach((cp) => {
                // for (let i = 0; i < radio.length; i++) {
                //     cp.addonProducts.map((cd) => {
                //         if (cd.addon_id == radio[i].getAttribute("data-addon-id")) {
                //             addons["addonProducts"].push({
                //                 addon_category_name: radio[i].name,
                //                 addon_id: parseInt(radio[i].getAttribute("data-addon-id")),
                //                 addon_name: radio[i].getAttribute("data-addon-name"),
                //                 quantity: quantity,
                //                 type: "SINGLE",
                //                 price: addonoffer(parseFloat(radio[i].value)),
                //                 itemPrice: addonoffer(item.selling_price),
                //                 singlePrice: parseFloat(addonoffer(item.selling_price)) + parseFloat(addonoffer(parseFloat(radio[i].value))),
                //                 totalPrice: (parseFloat(addonoffer(item.selling_price)) + parseFloat(addonoffer(parseFloat(radio[i].value))) * quantity),
                //             });
                //         } else {
                //             addons["addonProducts"].push({
                //                 addon_category_name: radio[i].name,
                //                 addon_id: parseInt(radio[i].getAttribute("data-addon-id")),
                //                 addon_name: radio[i].getAttribute("data-addon-name"),
                //                 quantity: quantity,
                //                 type: "SINGLE",
                //                 price: addonoffer(parseFloat(radio[i].value)),
                //                 itemPrice: addonoffer(item.selling_price),
                //                 singlePrice: parseFloat(addonoffer(item.selling_price)) + parseFloat(addonoffer(parseFloat(radio[i].value))),
                //                 totalPrice: (addonoffer(item.selling_price) * quantity) + (parseFloat(addonoffer(parseFloat(radio[i].value))) * quantity),
                //             });
                //         }
                //         return cd;
                //     })
                // }

                for (let i = 0; i < checkboxes.length; i++) {
                    cp.addonProducts.map((cd) => {
                        addons["addonProducts"].push({
                            id: parseInt(checkboxes[i].getAttribute("data-addon-id")),
                            name: checkboxes[i].getAttribute("data-addon-name"),
                            quantity: quantity,
                            type: "MULTIPLE",
                            price: parseFloat(checkboxes[i].value),
                            totalPrice: (item['prices']['price']) + (parseFloat(parseFloat(checkboxes[i].value)) * quantity)
                        });

                        return cd;
                    })
                }
            });
        } else {
            // for (let i = 0; i < radio.length; i++) {
            //     addons["addonProducts"].push({
            //         addon_category_name: radio[i].name,
            //         addon_id: parseInt(radio[i].getAttribute("data-addon-id")),
            //         addon_name: radio[i].getAttribute("data-addon-name"),
            //         quantity: quantity,
            //         type: "SINGLE",
            //         price: addonoffer(parseFloat(radio[i].value)),
            //         singlePrice: parseFloat(addonoffer(item.selling_price)) + parseFloat(addonoffer(parseFloat(radio[i].value))),
            //         itemPrice: addonoffer(item.selling_price),
            //         totalPrice: (addonoffer(item.selling_price) * quantity) + (parseFloat(addonoffer(parseFloat(radio[i].value))) * quantity),
            //     });
            //     item.stock = radio[i].getAttribute("data-addon-stock");
            // }

            for (let i = 0; i < checkboxes.length; i++) {
                addons["addonProducts"].push({
                    id: parseInt(checkboxes[i].getAttribute("data-addon-id")),
                    name: checkboxes[i].getAttribute("data-addon-name"),
                    quantity: quantity,
                    type: "MULTIPLE",
                    price: parseFloat(checkboxes[i].value),
                    totalPrice: (item['prices']['price']) + (parseFloat(parseFloat(checkboxes[i].value)) * quantity)
                });
            }
        }

        let newData = Object.assign({}, item, addons, {
            image: item?.images[0]?.url
        });
        if (cartProducts.items.length == 0 || newData?.shop_id === cartProducts?.shop_id) {
            dispatch(addCustomizationCart(newData));
        } else {
            setOtherStore(true);
            setStateItem(newData)
        }
        setQuantity(1)
    };

    const onMinus = () => {
        if (quantity === 1) {
            setCustomizeOpen(false)
        } else {
            let qty;
            qty = quantity - 1;
            setQuantity(qty)
        }
    }

    const onPlus = () => {
        let qty;
        qty = quantity + 1;
        setQuantity(qty)
    }

    return (
        <div>
            {storeList ? (
                <>
                    <div class="add-btn" onClick={() => setCustomizeOpen(true)} >
                        <span class="btn btn-outline" data-bs-target="#add-product" data-bs-toggle="modal" style={{ borderRadius: '8px' }}><i
                            class="ri-add-line"></i> ADD</span>
                    </div>
                </>
            ) : cartPage ? (
                <>
                    <div className="wishlist-item-add-round-btn" onClick={() => setCustomizeOpen(true)} >
                        <FaPlus />
                    </div>
                </>
            ) : cardView ? (
                <div className="cart mb-0" onClick={() => setCustomizeOpen(true)}>
                    <i className="ri-shopping-cart-2-fill"></i>
                </div>
            ) : cartProducts.items.find((cp) => cp.id === product?.id) !== undefined ? (
                <Link to="/bag">
                    <div className='store-to-cart-btn'>
                        To Cart
                    </div>
                </Link>
            ) : (
                <div className="store-item-add-btn" onClick={() => setCustomizeOpen(true)}  >  Add
                </div>
            )}
            <SwipeableDrawer
                anchor="bottom"
                open={customizeOpen}
                onClose={() => setCustomizeOpen(false)}
                onOpen={() => setCustomizeOpen(true)}
                swipeAreaWidth={20}
                transitionDuration={500}
                style={customizeOpen ? { zIndex: '99999999' } : {}}
            >
                <div className="d-flex align-items-center justify-content-center w-100">
                    <HiOutlineMinus size={35} color={"#00000024"} />
                </div>
                <div className="" style={{ paddingBottom: "7rem", }}>
                    <div className='d-flex justify-content-between align-items-center px-3 pb-2 '>
                        <div className="font-22 fw-700" style={{ color: '#070648', }}>
                            Customize
                        </div>
                        <div onClick={() => { setCustomizeOpen(false) }}>
                            <RiCloseCircleLine size={25} />
                        </div>
                    </div>
                    {cartPage ?
                        <div className='position-fixed bottom-0 w-100 py-4 px-3 d-flex bg-white'>
                            <div className="d-flex justify-content-between align-items-center me-3" >
                                <div className="store-item-remove-round-btn p-2.5" onClick={() => { onMinus() }} >
                                    <FaMinus />
                                </div>
                                <span className='px-3'>
                                    {quantity}
                                </span>
                                <div className="store-item-add-round-btn p-2.5" onClick={() => { onPlus() }}>
                                    <FaPlus />
                                </div>
                            </div>
                            <div className='customize-add-cart-btn text-center' onClick={() => {
                                let cp = { ...product, quantity: quantity };
                                _processAddons(cp);
                                setCustomizeOpen(false);
                            }}>
                                Add to cart
                            </div>
                        </div>
                        :
                        <>
                            <div className='p-3'>
                                {product && product?.addons?.length > 0 && (
                                    <div className="mt-2" key={product.id}>
                                        {product && product?.addons?.map((addon, index) => (
                                            <div key={addon.id}>


                                                <label htmlFor={addon.name} className="d-flex align-items-center justify-content-between container">
                                                    <div className="d-flex align-items-center">

                                                        <input
                                                            type="checkbox"
                                                            className="check-cust"
                                                            color="black"
                                                            data-addon-id={addon.id}
                                                            data-addon-name={addon['addon']['name']}
                                                            id={addon.id}
                                                            value={parseFloat(addon.price)}
                                                        />
                                                        <span className="checkmark"></span>

                                                        <span className="fw-550 text-slate-400 addon-label" style={{ marginLeft: '2rem' }}>
                                                            {addon['addon']['name']}
                                                        </span>
                                                    </div>

                                                    {/* Addon Price */}
                                                    <div className="fw-550 text-slate-400 addon-price">
                                                        <h6 className="fw-semibold mb-0">
                                                            ₹{parseFloat(addon.price)}
                                                        </h6>
                                                    </div>
                                                </label>




                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <hr style={{ color: '#c7c7c7', margin: '0px' }} />
                        </>}
                    <div className='position-fixed bottom-0 w-100 py-4 px-3 d-flex bg-white'>
                        <div className="d-flex justify-content-between align-items-center me-3" >
                            <div className="store-item-remove-round-btn p-2.5" onClick={() => { onMinus() }} >
                                <FaMinus />
                            </div>
                            <span className='px-3'>
                                {quantity}
                            </span>
                            <div className="store-item-add-round-btn p-2.5" onClick={() => { onPlus() }}>
                                <FaPlus />
                            </div>
                        </div>
                        <div className='customize-add-cart-btn text-center' onClick={() => {
                            let cp = { ...product, quantity: quantity, price: product['prices']['orignal_price'] };
                            _processAddons(cp);
                            setCustomizeOpen(false);
                        }}>
                            Add to cart
                        </div>
                    </div>
                </div>
            </SwipeableDrawer>

            <SwipeableDrawer
                anchor="bottom"
                open={otherStore}
                onClose={() => { setOtherStore(false); setCustomizeOpen(true) }}
                swipeAreaWidth={20}
                transitionDuration={500}
                style={otherStore ? { zIndex: '99999999' } : {}}
            >
                <div className="flex items-center justify-center w-100">
                    <HiOutlineMinus size={35} color={"#00000024"} />
                </div>
                <div className="" style={{ paddingBottom: "4rem", }}>
                    <div className='flex justify-between items-center px-3 pb-2 '>
                        <div className="font-22 fw-700" style={{ color: '#070648', }}>
                            <h1 className="mt-2 mb-0 font-weight-black h5">
                                Replace items in the cart?
                            </h1>
                        </div>
                        <div onClick={() => { setOtherStore(false); setCustomizeOpen(true) }}>
                            <RiCloseCircleLine size={25} />
                        </div>
                    </div>
                    <div>
                        <div className="px-3 col-12 pb-3 d-flex justify-content-between align-items-center">
                        </div>
                        <div className="px-3 mb-20">
                            Your cart contains items from other
                            store. Do you want to discard the
                            selection and add items from this store?
                        </div>
                        <div className="d-flex justify-content-around w-100 px-3 mt-3 fixed bottom-3">
                            <div className="text-center mr-4 w-100">
                                <button
                                    className="single-product-add-btn py-3 px-2 w-100 relative"
                                    onClick={() => { setOtherStore(false); setCustomizeOpen(true) }}
                                >
                                    NO
                                </button>
                            </div>
                            <div className="text-center w-100">
                                <button
                                    className="single-product-yes py-3 px-2 w-100 text-center text-white position-relative"
                                    onClick={() => {
                                        dispatch(clearCart())
                                        setOtherStore(false)
                                        dispatch(addCustomizationCart(stateItem));
                                    }}
                                >
                                    YES
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </SwipeableDrawer>
        </div>
    )
}

export default Customization
